import { useCallback } from "react";

import { db } from "../services/firebase";

import {
  where,
  getCountFromServer,
  query,
  collection,
  CollectionReference,
  QueryConstraint,
  orderBy,
} from "firebase/firestore";

import { Knock, KnockStatus } from "../types";

const COLLECTION = "knocks";

export default function useFetchKnocksCount() {
  return useCallback(
    ({ status, userId }: { status?: KnockStatus; userId?: string }) => {
      const collectionRef = collection(
        db,
        COLLECTION
      ) as CollectionReference<Knock>;

      const whereConstraints: QueryConstraint[] = [];
      const orderConstraints: QueryConstraint[] = [];

      if (status !== undefined) {
        whereConstraints.push(
          where("status", "==" , status)
        );
        orderConstraints.push(orderBy("createdAt", "desc"));
      }

      if (userId) {
        whereConstraints.push(
          where("userId", "==", userId)
          
        );
        orderConstraints.push(orderBy("updatedAt", "desc"));
      }

      

      const constraints: QueryConstraint[] = [
        ...whereConstraints,
        ...orderConstraints,
      ];

      return getCountFromServer(query(collectionRef, ...constraints));
    },
    []
  );
}
