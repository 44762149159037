import { doc, getDoc } from "firebase/firestore";
import { LocationProfile } from "../types/location.interface";
import { db } from "./firebase";


const COLLECTION = 'locationProfiles';

export const getLocationProfile = async (profileId: string): Promise<LocationProfile | null> => {
  const locationProfileRef = doc(db, COLLECTION, profileId);
  const locationProfileSnap = await getDoc(locationProfileRef);

  if (!locationProfileSnap.exists()) {
    return null;
  }

  return locationProfileSnap.data() as LocationProfile;
}