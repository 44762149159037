import {
  CollectionReference,
  DocumentSnapshot,
  QueryConstraint,
  collection,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { db } from "../services/firebase";
import { Knock, KnockStatus } from "../types";
import { useFirestoreCollection } from "reactfire";

const COLLECTION = "knocks";

type useFetchKnocksParams = {
  status?: KnockStatus;
  userId?: string;
  params: {
    cursor?: DocumentSnapshot;
    itemsPerPage: number;
  };
};

export default function useFetchKnocks({
  status,
  userId,
  params,
}: useFetchKnocksParams) {
  const orderConstraints: QueryConstraint[] = [];
  const whereConstraints: QueryConstraint[] = [];

  if (status !== undefined) {
    whereConstraints.push(where("status", "==", status));
  }

  if (userId) {
    whereConstraints.push(where("userId", "==", userId));

    // available index for userId
    orderConstraints.push(orderBy("updatedAt", "desc"));
  } else {
    orderConstraints.push(orderBy("createdAt", "desc"));
  }

  const constraints: QueryConstraint[] = [
    ...whereConstraints,
    ...orderConstraints,
    limit(params.itemsPerPage),
  ];

  if (params.cursor) {
    constraints.push(startAfter(params.cursor));
  }

  const collectionRef = collection(
    db,
    COLLECTION
  ) as CollectionReference<Knock>;

  const q = query(collectionRef, ...constraints);

  return useFirestoreCollection(q);
}
