import { ProfilesList } from "../../components/lists/ProfilesList";
import { PageHeader } from "../../components/shared";

export const ProfilesIndexPage = () => {
  return (
    <>
      <div className="flex items-end justify-between">
        <PageHeader>Users</PageHeader>

      </div>
      <div className="mt-8">
        <ProfilesList />
      </div>
    </>
  );
};
