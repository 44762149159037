import { KnocksTable } from "../../../components/knocks";

const CustomerView = ({ profileId }: { profileId: string }) => {
  return (
    <div>
      <KnocksTable userId={profileId} itemsPerPage={10} />
    </div>
  );
};

export default CustomerView;
