import Card from "../../../components/shared/Card";
import { Profile } from "../../../types";
import DateTimeDisplay from "../../../ui/dateTimeDisplay";
import useFetchKnocksCount from "../../../hooks/useFetchKnocksCount";
import { useEffect, useState } from "react";
import Title from "../../../components/shared/Title";

const StatsInfo = ({ profile }: { profile: Profile }) => {
  const fetchKnocksCount = useFetchKnocksCount();
  const [knocksCount, setKnocksCount] = useState<number>(0);

  useEffect(() => {
    fetchKnocksCount({ userId: profile.id }).then((result) =>
      setKnocksCount(result.data().count)
    );
  }, [fetchKnocksCount, profile]);

  return (
    <Card>
      <Title>Stats</Title>
      <div className="flex flex-col gap-4 mt-6">
        <p className="flex justify-between">
          <span>Creation Date</span>
          <strong>
            <DateTimeDisplay date={profile.createdAt} />
          </strong>
        </p>
        <p className="flex justify-between">
          <span>Total Requests</span>
          <strong>{knocksCount}</strong>
        </p>
      </div>
    </Card>
  );
};

export default StatsInfo;
