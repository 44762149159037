import { useEffect, useState, useRef } from "react";
import { ProfileService } from "../../services/profile.service";
import { Profile } from "../../types";
import { TablePagination } from "../shared/TablePagination/TablePagination";
import { Link } from "react-router-dom";
import { routeNames } from "../../routes";
import useProfilesCount from "../../hooks/useProfilesCount";

const ITEMS_PER_PAGE = 10;

export const ProfilesList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredProfiles, setFilteredProfiles] = useState<Profile[]>([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [page, setPage] = useState(0);
  const [profileCount, setProfileCount] = useState(0);
  const cursors = useRef<Map<number, string>>(new Map());
  const getProfilesCount = useProfilesCount();

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    const fetchProfiles = async () => {
      setLoading(true);
      try {
        const profiles = await ProfileService.search({
          searchTerm: debouncedSearchTerm,
          params: {
            cursor: cursors.current.get(page),
            itemsPerPage: ITEMS_PER_PAGE,
          },
        });
        setFilteredProfiles(profiles);

        if (!debouncedSearchTerm) {
          const profileCount = await getProfilesCount();
          setProfileCount(profileCount.data().count);

          if (profileCount) {
            cursors.current.set(page + 1, profiles[profiles.length - 1].id);
          }
        } else {
          setProfileCount(profiles.length);
        }
      } catch (error) {
        console.error("Failed to fetch profiles", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfiles();
  }, [debouncedSearchTerm, page, getProfilesCount]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const onPageChanged = (nextPage: number) => {
    setPage(nextPage);
  };

  return (
    <>
      <div className="overflow-x-auto">
        <input
          type="text"
          placeholder="Search by email, phone number, or ID"
          value={searchTerm}
          onChange={handleSearchChange}
          className="input input-bordered w-full"
        />
        <table className="table w-full mt-4">
          <thead>
            <tr>
              <th>ID</th>
              <th>Full name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={5} className="text-center">
                  Loading...
                </td>
              </tr>
            ) : (
              <>
                {filteredProfiles.map((profile) => (
                  <tr key={profile.id}>
                    <td>{profile.id}</td>
                    <td>
                      {profile.name} {profile.lastName}
                    </td>
                    <td>{profile.email}</td>
                    <td>{profile.phoneNumber}</td>
                    <td>
                      <Link
                        to={routeNames["profiles.show"](profile.id)}
                        className="btn btn-sm btn-outline"
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
        <div className="mt-4">
          <TablePagination
            currentPage={page}
            itemsPerPage={ITEMS_PER_PAGE}
            onPageChange={onPageChanged}
            recordsCount={profileCount}
          />
        </div>
      </div>
    </>
  );
};
