import Card from "../../../components/shared/Card";
import Title from "../../../components/shared/Title";
import { Profile } from "../../../types";
import ProfileLocationInfo from "./LocationInfo";

const ProfileBasicInfo = ({ profile }: { profile: Profile }) => {
  return (
    <Card>
      <Title>Basic info</Title>
      <div className="flex flex-col gap-4 mt-6">
        <p className="flex justify-between">
          <span>ID</span>
          <strong>{profile.id}</strong>
        </p>
        <p className="flex justify-between">
          <span>Full name</span>
          <strong>
            {profile.name} {profile.lastName}
          </strong>
        </p>
        <p className="flex justify-between">
          <span>Email</span>
          <strong>{profile.email}</strong>
        </p>
        <p className="flex justify-between">
          <span>Phone Number</span>
          <strong>{profile.phoneNumber}</strong>
        </p>
        <p className="flex justify-between">
          <span>App language</span>
          <strong>{profile.locale}</strong>
        </p>
        <ProfileLocationInfo profileId={profile.id} />
      </div>
    </Card>
  );
};

export default ProfileBasicInfo;
