import { DocumentSnapshot } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useFetchKnocks from "../../../hooks/useFetchKnocks";
import { Knock, KnockChannel, KnockFlow, KnockStatus } from "../../../types";
import { BiBot } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import { routeNames } from "../../../routes";
import useFetchCategories from "../../../hooks/useFetchCategories";
import { KnocksTablePagination } from "./KnocksTablePagination";
import DateTimeDisplay from "../../../ui/dateTimeDisplay";
import TimeAgo from "timeago-react";

type KnocksTableProps = {
  status?: KnockStatus;
  userId?: string;
  itemsPerPage?: number;
};

const ITEMS_PER_PAGE = 100;

export const KnocksTable = ({
  status,
  userId,
  itemsPerPage = ITEMS_PER_PAGE,
}: KnocksTableProps) => {
  const location = useLocation();
  const [page, setPage] = useState(0);

  const categoriesFetch = useFetchCategories();

  const categories = useMemo(() => {
    return categoriesFetch.data?.docs.map((doc) => doc.data()) ?? [];
  }, [categoriesFetch.data]);

  const cursors = useRef<Map<number, DocumentSnapshot>>(new Map());

  const knocksFetch = useFetchKnocks({
    status,
    userId,
    params: {
      cursor: cursors.current.get(page),
      itemsPerPage,
    },
  });

  useEffect(() => {
    setPage(0);
  }, [status]);

  const knocks = useMemo(() => {
    return knocksFetch.data?.docs.map((doc) => doc.data()) ?? [];
  }, [knocksFetch.data]);

  // callback called when changing page
  const onPageChanged = useCallback(
    (nextPage: number) => {
      setPage((page) => {
        // first, we save the last document as page's cursor
        cursors.current.set(
          page + 1,
          knocksFetch.data.docs[knocksFetch.data.docs.length - 1]
        );

        // then we update the state with the next page's number
        return nextPage;
      });
    },
    [knocksFetch.data]
  );

  const statusBadgeClass = (knock: Knock) => {
    switch (knock.status) {
      case "searching":
        return "badge-info";
      case "active":
        return "badge-success";
      case "expired":
        return "badge-error";
      default:
        return "";
    }
  };

  const showStatusColumn = status === undefined;
  const showUserColumn = userId === undefined;
  const groupedColumnCount = showStatusColumn ? 7 : 6;

  return (
    <div className="overflow-x-auto">
      <table className="table w-full">
        <thead>
          <tr>
            {showUserColumn ? <th>Customer name</th> : null}
            <th className="hidden lg:table-cell">Category</th>
            {showStatusColumn ? (
              <th className="hidden lg:table-cell">Status</th>
            ) : null}
            <th className="hidden lg:table-cell">Accepted</th>
            <th className="hidden lg:table-cell">Created at</th>
            <th className="hidden lg:table-cell">User flow</th>
            <th className="hidden lg:table-cell">Action</th>
          </tr>
        </thead>
        <tbody>
          {knocksFetch.status === "loading" ||
          categoriesFetch.status === "loading" ? (
            <tr>
              <td colSpan={groupedColumnCount}>Loading...</td>
            </tr>
          ) : knocks.length > 0 ? (
            knocks.map((knock: Knock) => {
              const category = categories.find(
                (item) => item.id === knock.categoryId
              );

              let acceptedBadgeClass =
                (knock.acceptedRequestsCount ?? knock.chatsCount) > 0
                  ? "badge-success"
                  : "badge-error";

              const statusBadge = (
                <span className={`badge ${statusBadgeClass(knock)}`}>
                  {knock.status}
                </span>
              );

              const getFlowBadge = (flow: KnockFlow, channel: KnockChannel) => {
                switch (flow) {
                  case "aiAssisted":
                  case "guestAiAssisted":
                    return (
                      <span className="badge badge-secondary">
                        <BiBot className="mr-1" /> {channel} AI
                      </span>
                    );
                  case "guestPickCategory":
                  case "pickCategory":
                    return (
                      <span className="badge badge-success">
                        {channel} Category
                      </span>
                    );
                  case "resendRequest":
                    return (
                      <span className="badge badge-warning">
                        {channel} Resent
                      </span>
                    );
                  default:
                    return channel || flow ? (
                      <span className="badge">
                        {channel} {flow}
                      </span>
                    ) : (
                      <></>
                    );
                }
              };

              const acceptedBadge = (
                <span className={`badge ${acceptedBadgeClass}`}>
                  {knock.acceptedRequestsCount ?? knock.chatsCount}
                </span>
              );

              const flowBadge = getFlowBadge(knock.flow, knock.channel);

              const showLink = (
                <Link
                  to={{
                    pathname: routeNames["knocks.show"](knock.id),
                    search: `?referrer=${encodeURIComponent(
                      location.pathname
                    )}`,
                  }}
                >
                  <button className="btn btn-primary btn-sm mr-2">
                    Details
                  </button>
                </Link>
              );

              return (
                <tr key={knock.id}>
                  {showUserColumn ? (
                    <th className="hidden lg:table-cell">
                      <Link
                        to={routeNames["profiles.show"](knock.userId)}
                        className="underline"
                      >
                        <div>{knock.customerName}</div>
                      </Link>
                    </th>
                  ) : null}
                  <td className="hidden lg:table-cell">{category?.name}</td>
                  {showStatusColumn ? (
                    <td className="hidden lg:table-cell">{statusBadge}</td>
                  ) : null}
                  <td className="hidden lg:table-cell">{acceptedBadge}</td>
                  <td className="hidden lg:table-cell">
                    <DateTimeDisplay date={knock.createdAt} />
                    <br />
                    <TimeAgo datetime={knock.createdAt} />
                  </td>
                  <td className="hidden lg:table-cell">{flowBadge}</td>
                  <td className="hidden lg:table-cell">{showLink}</td>
                  <td className="lg:hidden">
                    <div className="flex flex-col lg:table-cell text-sm">
                      <p className="text-lg font-bold ">{knock.customerName}</p>
                      <div>{category?.name}</div>
                      <div className="mt-2">Status: {statusBadge}</div>
                      <div className="mt-2">Accepted: {acceptedBadge}</div>
                      <div className="mt-2">User flow: {flowBadge}</div>
                      <div className="mt-2">
                        <DateTimeDisplay date={knock.createdAt} /> -{" "}
                        <TimeAgo datetime={knock.createdAt} />
                      </div>
                      <div className="mt-2">{showLink}</div>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={groupedColumnCount} className="italic">
                There are no requests
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={groupedColumnCount}>
              <KnocksTablePagination
                currentPage={page}
                itemsPerPage={ITEMS_PER_PAGE}
                status={status}
                onPageChange={onPageChanged}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
