import { useCallback } from "react";

import { db } from "../services/firebase";

import {
  getCountFromServer,
  query,
  collection,
  CollectionReference,
  QueryConstraint,
  orderBy,
} from "firebase/firestore";

import { Profile } from "../types";

const COLLECTION = "profiles";

export default function useProfilesCount() {
  return useCallback(
    () => {
      const collectionRef = collection(
        db,
        COLLECTION
      ) as CollectionReference<Profile>;

      const whereConstraints: QueryConstraint[] = [];
      const orderConstraints: QueryConstraint[] = [];

      orderConstraints.push(orderBy("createdAt", "desc"));

      const constraints: QueryConstraint[] = [
        ...whereConstraints,
        ...orderConstraints,
      ];

      return getCountFromServer(query(collectionRef, ...constraints));
    },
    []
  );
}
