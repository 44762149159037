import Card from "../../../components/shared/Card";
import { Profile } from "../../../types";
import DateTimeDisplay from "../../../ui/dateTimeDisplay";
import Title from "../../../components/shared/Title";

const ProviderStatsInfo = ({ profile }: { profile: Profile }) => {
  return (
    <>
      {profile.trialCredits || profile.subscriptionStatus ? (
        <Card>
          <div className="flex flex-col gap-4 ">
            {profile.trialCredits ? (
              <>
                <Title>Provider Stats</Title>
                <p className="flex justify-between">
                  <span> Trial Credits</span>
                  <strong>{profile.trialCredits ?? "-"}</strong>
                </p>
                <p className="flex justify-between">
                  <span> Express Credits</span>
                  <strong>{profile.expressCredits ?? "-"}</strong>
                </p>
                <p className="flex justify-between">
                  <span> Invoices and estimates Credits</span>
                  <strong>{profile.trialInvoiceCredits ?? "-"}</strong>
                </p>
              </>
            ) : null}
            {profile.subscriptionStatus ? (
              <>
                <Title>Subscription</Title>
                <p className="flex justify-between">
                  <span> Subscription Status</span>
                  <strong>{profile.subscriptionStatus ?? "-"}</strong>
                </p>

                <p className="flex justify-between">
                  <span> Subscription Provider</span>
                  <strong>{profile.subscriptionProvider ?? "-"}</strong>
                </p>
                <p className="flex justify-between">
                  <span>Subscription expiration date</span>
                  <strong>
                    {profile.subscriptionExpiresAt ? (
                      <DateTimeDisplay date={profile.subscriptionExpiresAt} />
                    ) : null}
                  </strong>
                </p>
              </>
            ) : null}
          </div>
        </Card>
      ) : null}
    </>
  );
};

export default ProviderStatsInfo;
