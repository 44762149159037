import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ProfileService } from "../../services";
import { routeNames } from "../../routes";
import { BiArrowBack } from "react-icons/bi";
import { PageHeader } from "../../components/shared";
import ProfileBasicInfo from "./profilesShowPage/BasicInfo";
import StatsInfo from "./profilesShowPage/StatsInfo";
import ProviderView from "./profilesShowPage/ProviderVIew";
import CustomerView from "./profilesShowPage/CustomerView";
import ProviderStatsInfo from "./profilesShowPage/ProviderStatsInfo";

const ProfileShowPage = () => {
  const { profileId } = useParams<{ profileId: string }>();
  const [profile, setProfile] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<"customer" | "provider">(
    "customer"
  );

  useEffect(() => {
    const fetchProfile = async (profileId: string) => {
      try {
        const profile = await ProfileService.getById(profileId);
        setProfile(profile);
      } catch (err) {
        setError("Error fetching profile data");
      } finally {
        setLoading(false);
      }
    };

    if (profileId) {
      fetchProfile(profileId);
    }
  }, [profileId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      {profile && (
        <div className="flex flex-col gap-8">
          <div className="flex items-end">
            <Link
              to={routeNames["profiles.index"]}
              onClick={() => window.history.back()}
            >
              <BiArrowBack size={30} className="mr-2" />
            </Link>
            <PageHeader>{`${profile.name} profile`}</PageHeader>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <ProfileBasicInfo profile={profile} />
            <StatsInfo profile={profile} />
            <ProviderStatsInfo profile={profile} />
          </div>

          <div className="flex space-x-4">
            <button
              className={`py-2 px-4 ${
                activeTab === "customer" ? "border-b-2 border-blue-500" : ""
              }`}
              onClick={() => setActiveTab("customer")}
            >
              As customer
            </button>
            <button
              className={`py-2 px-4 ${
                activeTab === "provider" ? "border-b-2 border-blue-500" : ""
              }`}
              onClick={() => setActiveTab("provider")}
            >
              As provider
            </button>
          </div>

          {activeTab === "customer" ? (
            <CustomerView profileId={profile.id} />
          ) : (
            <ProviderView profileId={profile.id} />
          )}
        </div>
      )}
    </>
  );
};

export default ProfileShowPage;
