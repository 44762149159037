import { useCallback, useEffect, useState } from "react";
import { BiInfoCircle } from "react-icons/bi";
import { Link } from "react-router-dom";
import { routeNames } from "../../routes";
import { CategoriesService } from "../../services/categories.service";
import { PostService } from "../../services/post.service";
import { Category, Post, PostStatus } from "../../types";

import Moment from "moment";

type PostListProps = {
  status?: PostStatus;
  userId?: string;
};

export const PostsList = ({ status, userId }: PostListProps) => {
  const [categories, setCategories] = useState<Category[]>();
  const [posts, setPosts] = useState<Post[]>();
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    const unsubscribePosts = PostService.getAllSuscription({
      status,
      userId,
      onDataChange: setPosts,
    });

    const unsubscribeCategories = CategoriesService.getAllSuscription({
      onDataChange: setCategories,
    });

    return () => {
      unsubscribePosts();
      unsubscribeCategories();
    };
  }, [status, userId]);

  const filterPosts = useCallback(() => {
    return (
      posts?.filter((post) =>
        post.businessName.toLowerCase().includes(search.toLowerCase())
      ) ?? []
    );
  }, [posts, search]);

  const filteredPosts = filterPosts();

  return (
    <>
      <div className="w-full mb-3">
        <input
          className="input w-full"
          placeholder="Search by name..."
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <div className="overflow-x-auto">
        <table className="table w-full">
          <thead>
            <tr>
              <th>Name</th>
              <th className="hidden lg:table-cell">Category</th>
              <th className="hidden lg:table-cell">Created at</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {posts === undefined || categories === undefined ? (
              <tr>
                <th colSpan={4}>...</th>
              </tr>
            ) : posts!.length > 0 ? (
              filteredPosts.length === 0 ? (
                <tr>
                  <td colSpan={4} className="italic">
                    There are no posts with the provided business name.
                  </td>
                </tr>
              ) : (
                filteredPosts.map((post: Post) => {
                  const category = categories.find(
                    (item) => item.id === post.categoryId
                  );

                  return (
                    <tr key={post.id} className="flex flex-col lg:table-row">
                      <th>
                        <div>{post.businessName}</div>
                        <div className="text-sm font-normal lg:hidden">
                          <div>{category?.name}</div>
                          <div>
                            Created at:{" "}
                            {Moment(post.createdAt).format("MMM Do YY")}
                          </div>
                        </div>
                      </th>
                      <td className="hidden lg:table-cell">{category?.name}</td>
                      <td className="hidden lg:table-cell">
                        {Moment(post.createdAt).format("MMM Do YY")}
                      </td>
                      <td>
                        <Link to={routeNames["posts.show"](post.id)}>
                          <button className="btn btn-primary btn-sm mr-2">
                            Details
                          </button>
                        </Link>
                      </td>
                    </tr>
                  );
                })
              )
            ) : (
              <tr>
                <td colSpan={4} className="italic">
                  <div className="flex items-center">
                    <BiInfoCircle className="mr-2" />
                    There are no {status} posts.
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
