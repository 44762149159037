import React, { useState } from "react";
import { generateQuestions, generateRequestEnhanced } from "../../../services"; // Adjust the import path if needed
import {
  AIQuestionData,
  RequestEnhancedPayload,
  RequestEnhancedResponse,
} from "../../../types"; // Adjust the import path if needed
import decodeAndDecompress from "../../../utils/decompressGzip";
import { Modal } from "../../shared/Modal/Modal";

const QuestionGeneratorDemo: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [questionData, setQuestionData] = useState<AIQuestionData | null>(null);
  const [inputText, setInputText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [answers, setAnswers] = useState<{ [key: string]: string }>({});
  const [enhancedResponse, setEnhancedResponse] =
    useState<RequestEnhancedResponse | null>(null);
  const [inputError, setInputError] = useState<string | null>(null);
  const [answerError, setAnswerError] = useState<string | null>(null);
  const [locale, setLocale] = useState<string>("en");

  const handleGenerateQuestion = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    setInputError(null);
    setEnhancedResponse(null);

    if (inputText.trim().length < 8) {
      setInputError("The request should be at least 8 characters long.");
      return;
    }

    setLoading(true);

    try {
      const response = await generateQuestions({
        question: inputText,

        locale,
      });
      if (response.fullPrompt) {
        response.fullPrompt = decodeAndDecompress(response.fullPrompt);
      }

      setQuestionData(response);
      setAnswers({});
    } catch (err) {
      console.error("Error generating question:", err);
      setError("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleAnswerSelect = (questionKey: string, answer: string) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionKey]: answer,
    }));

    setAnswerError(null);
  };

  const handleSubmitAnswers = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setAnswerError(null);

    if (questionData === null) return;

    if (questionData.questions.some((q) => answers[q.question] === undefined)) {
      setAnswerError(
        "You must respond to all the questions to generate the enhanced request."
      );
      return;
    }

    const formattedAnswers = questionData.questions.map((question) => ({
      question: question.question,
      answer: answers[question.question] || "",
    }));

    const payload: RequestEnhancedPayload = {
      initialRequest: inputText,
      responseDetails: formattedAnswers,
      locale,
    };

    setLoading(true);

    try {
      const response: RequestEnhancedResponse = await generateRequestEnhanced(
        payload
      );
      setEnhancedResponse(response);
    } catch (err) {
      console.error("Error in generateRequestEnhanced:", err);
      setError(
        "Something went wrong with the enhanced request. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4 text-center">
        Tell Us About the Problem
      </h2>

      <form onSubmit={handleGenerateQuestion}>
        <textarea
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="What's the issue you need help with?"
          className={`w-full p-3 border rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
            inputError ? "border-red-500" : ""
          }`}
        />
        {inputError && <p className="text-red-500 mt-1">{inputError}</p>}

        {/* Locale Selector */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Select Locale
          </label>
          <select
            value={locale}
            onChange={(e) => setLocale(e.target.value)}
            className="block w-full bg-white border border-gray-400 rounded py-2 px-3 text-gray-700 focus:outline-none"
          >
            <option value="en">English</option>
            <option value="es">Spanish</option>
          </select>
        </div>

        <button
          type="submit"
          className={`w-full bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 transition ${
            loading ? "cursor-not-allowed" : ""
          }`}
          disabled={loading}
        >
          {loading ? "Finding Questions..." : "Next"}
        </button>
      </form>

      {error && <p className="text-red-500 mt-4">{error}</p>}

      {questionData && (
        <div>
          <form onSubmit={handleSubmitAnswers} className="mt-6">
            <h3 className="text-xl font-semibold mb-2">
              We just need a little more info:
            </h3>

            {questionData.questions.map((question, index) => (
              <div key={index} className="bg-gray-100 p-4 mb-4 rounded-lg">
                <p className="font-semibold">{question.question}</p>
                <div className="mt-2">
                  {question.answers.map((answer, answerIndex) => (
                    <button
                      key={answerIndex}
                      type="button"
                      className={`mr-2 mb-2 py-2 px-4 rounded-lg focus:outline-none ${
                        answers[question.question] === answer
                          ? "bg-blue-500 text-white"
                          : "bg-gray-200 text-black"
                      }`}
                      onClick={() =>
                        handleAnswerSelect(question.question, answer)
                      }
                    >
                      {answer}
                    </button>
                  ))}
                </div>
              </div>
            ))}

            {answerError && <p className="text-red-500 mt-1">{answerError}</p>}

            {/* Display a beautiful tailwind box to let the user know if the request is illegal */}
            {questionData.isIllegal && (
              <div className="bg-red-100 border-l-4 border-red-600 p-4 rounded-lg shadow-md transition-all duration-300">
                <h4 className="text-xl font-semibold text-red-800">
                  This Request is Illegal
                </h4>
                <button
                  type="button"
                  onClick={() => setShowModal(true)}
                  className="w-full bg-gradient-to-r from-red-600 to-red-500 text-white py-2 px-4 rounded-lg shadow-md hover:scale-105 focus:outline-none focus:ring-4 focus:ring-red-300 hover:ring-red-500 mt-4 transition-all duration-300 transform hover:translate-x-1"
                >
                  View Full Prompt
                </button>
              </div>
            )}

            {/* Display a beautiful tailwind box to let the user know if the request is unrelated */}
            {questionData.isUnrelated && !questionData.isIllegal && (
              <div className="bg-yellow-100 border-l-4 border-yellow-600 p-4 rounded-lg shadow-md transition-all duration-300">
                <h4 className="text-xl font-semibold text-yellow-800">
                  This Request is Unrelated
                </h4>
                <button
                  type="button"
                  onClick={() => setShowModal(true)}
                  className="w-full bg-gradient-to-r from-yellow-600 to-yellow-500 text-white py-2 px-4 rounded-lg shadow-md hover:scale-105 focus:outline-none focus:ring-4 focus:ring-yellow-300 hover:ring-yellow-500 mt-4 transition-all duration-300 transform hover:translate-x-1"
                >
                  View Full Prompt
                </button>
              </div>
            )}

            {/* Display a beautiful tailwind box to let the user know if the request is legal and related */}
            {!questionData.isUnrelated && !questionData.isIllegal && (
              <div className="bg-green-100 border-l-4 border-green-600 p-4 rounded-lg shadow-md transition-all duration-300">
                <h4 className="text-xl font-semibold text-green-800">
                  This Request is Legal and Related
                </h4>
                <button
                  type="button"
                  onClick={() => setShowModal(true)}
                  className="w-full bg-gradient-to-r from-green-600 to-green-500 text-white py-2 px-4 rounded-lg shadow-md hover:scale-105 focus:outline-none focus:ring-4 focus:ring-green-300 hover:ring-green-500 mt-4 transition-all duration-300 transform hover:translate-x-1"
                >
                  View Full Prompt
                </button>
              </div>
            )}

            <button
              type="submit"
              className="w-full bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mt-4"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Send Your Answers"}
            </button>
          </form>

          {/* Display Modal with Full Prompt */}
          <Modal
            show={showModal}
            title="Full Prompt"
            onCloseModal={() => setShowModal(false)}
          >
            <div className="space-y-6">
              {/* Is Illegal Section */}
              {questionData.isIllegal && (
                <div className="bg-red-200 border-l-4 border-red-600 p-4 rounded-lg shadow-md transition-all duration-300">
                  <h4 className="text-xl font-semibold text-red-800">
                    This Request is Illegal
                  </h4>
                  <p className="mt-2 text-gray-700">
                    Predicted Request Details:
                  </p>
                  <p className="text-gray-800 whitespace-pre-wrap">
                    {questionData.fullPrompt}
                  </p>
                </div>
              )}

              {/* Is Unrelated Section */}
              {questionData.isUnrelated && (
                <div className="bg-yellow-200 border-l-4 border-yellow-600 p-4 rounded-lg shadow-md transition-all duration-300">
                  <h4 className="text-xl font-semibold text-yellow-800">
                    This Request is Unrelated
                  </h4>
                  <p className="mt-2 text-gray-700">
                    Predicted Request Details:
                  </p>
                  <p className="text-gray-800 whitespace-pre-wrap">
                    {questionData.fullPrompt}
                  </p>
                </div>
              )}

              {/* Else Unrelated Section */}
              {!questionData.isUnrelated && !questionData.isIllegal && (
                <div className="bg-green-200 border-l-4 border-green-600 p-4 rounded-lg shadow-md transition-all duration-300">
                  <h4 className="text-xl font-semibold text-green-800">
                    This Request is Legal and Related
                  </h4>
                  <p className="mt-2 text-gray-700">
                    Predicted Request Details:
                  </p>
                  <p className="text-gray-800 whitespace-pre-wrap">
                    {questionData.fullPrompt}
                  </p>
                </div>
              )}
            </div>
          </Modal>
        </div>
      )}

      {/* Display Enhanced Response */}
      {enhancedResponse && (
        <div className="mt-6 bg-gray-100 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Request Enhanced</h3>
          <p className="text-lg">
            <span className="font-semibold">Summary:</span>{" "}
            {enhancedResponse.summary}
          </p>
          <p className="text-lg">
            <span className="font-semibold">Category:</span>{" "}
            {enhancedResponse.inferredCategory.categoryName}
          </p>
        </div>
      )}
    </div>
  );
};

export default QuestionGeneratorDemo;
