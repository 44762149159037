import { collection, doc, getDoc, getDocs, limit, orderBy, query, startAt, where } from "firebase/firestore";
import { Profile } from "../types";
import { db } from "./firebase";

const COLLECTION = "profiles";

const getById = async (profileId: string): Promise<Profile> => {
  const docRef = doc(db, COLLECTION, profileId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data() as Profile;
  } else {
    throw new Error("not-found" + profileId);
  }
};


type searchParams = {
  searchTerm?: string;
  params: {
    cursor?: string;
    itemsPerPage: number;
  };
};


const search = async ({searchTerm, params}: searchParams ): Promise<Profile[]> => {
  const results: Profile[] = [];
  if(!searchTerm) {

  const collectionRef = collection(db, COLLECTION);


  let q;

  if(!params.cursor){
    q = query(collectionRef, orderBy("createdAt",'desc'), limit(params.itemsPerPage));
  }else {
    const id = params.cursor;
    const docRef =  doc(db,COLLECTION,id);
    const docSnap = await getDoc(docRef);
    q = query(collectionRef, orderBy("createdAt",'desc'), startAt(docSnap), limit(params.itemsPerPage));
  }

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    results.push(doc.data() as Profile);
  });

  }else{

  const collectionRef = collection(db, COLLECTION);
  const queries = [
    query(collectionRef, where("name", "==", searchTerm)),
    query(collectionRef, where("email", "==", searchTerm)),
    query(collectionRef, where("phoneNumber", "==", searchTerm)),
    query(collectionRef, where("lastName", "==", searchTerm)),
    query(collectionRef, where("id", "==", searchTerm))
  ];
  
  
  
  for (const q of queries) {
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      results.push(doc.data() as Profile);
    });
  }
}
  
  return results;
};

export const ProfileService = {
  getById,
  search,
};
