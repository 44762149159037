import {
  AIQuestionData,
  AIQuestionResponse,
  QuestionsKnowledgeResponse,
  PriceKnowledgeResponse,
} from "../types/assistantResponse.type";

const bearerToken = process.env.REACT_APP_DINGPROMPT_BEARER;

export async function generateQuestions(payload: any): Promise<AIQuestionData> {
  try {
    const url = `${process.env.REACT_APP_DINGPROMPT_ENDPOINT}/api/question-generate`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result: AIQuestionResponse = await response.json(); // Cast the result to the AIQuestionResponse type
    return result.data; // Return the data containing the questions and other fields
  } catch (error) {
    console.error("Error in generateQuestions:", error);
    throw error;
  }
}

export async function getSimilarity(serviceRequest: {
  description: string;
  postId: string;
}): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_DINGPROMPT_ENDPOINT}/api/service-request-creation-webhook`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify({
        service_request: serviceRequest,
      }), // Sending the service request in the payload
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    // Return the similarity score from the response
    return result.response.similarity; // Return the similarity value
  } catch (error) {
    console.error("Error in getSimilarity:", error);
    throw error;
  }
}

export async function generateRequestEnhanced(
  userResponseInfo: any
): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_DINGPROMPT_ENDPOINT}/api/enhanced-request-generate`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify(userResponseInfo), // Sending the userResponseInfo
      // object in the payload
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    return result.data; // Return the data containing the enhanced request
  } catch (error) {
    console.error("Error in generateRequestEnhanced:", error);
    throw error;
  }
}

export async function getPricePrediction(payload: any): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_PIPELINE_ENDPOINT}/api/v2/generate-price`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    return result.data;
  } catch (error) {
    console.error("Error in getPricePrediction:", error);
    throw error;
  }
}

export async function getQuestionsKnowledge(
  page = 1,
  pageSize = 10,
  categoryName = "",
  pendingOnly = false
): Promise<QuestionsKnowledgeResponse> {
  try {
    const url = `${process.env.REACT_APP_PIPELINE_ENDPOINT}/api/v2/fetch-questions-bq?page=${page}&pageSize=${pageSize}&categoryName=${categoryName}&pendingOnly=${pendingOnly}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result: QuestionsKnowledgeResponse = await response.json();

    return result;
  } catch (error) {
    console.error("Error in getQuestionsKnowledge:", error);
    throw error;
  }
}

export async function upsertQuestionsKnowledge(payload: any): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_PIPELINE_ENDPOINT}/api/v2/update-questions-bq`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    return result;
  } catch (error) {
    console.error("Error in upsertQuestionsKnowledge:", error);
    throw error;
  }
}

export async function uploadQuestionPinecone(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_PIPELINE_ENDPOINT}/api/v2/upload-questions-pinecone`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    return result;
  } catch (error) {
    console.error("Error in uploadPinecone:", error);
    throw error;
  }
}

export async function getRelatedKnowledge(
  page = 1,
  pageSize = 10,
  categoryName = "",
  pendingOnly = false
): Promise<QuestionsKnowledgeResponse> {
  try {
    const url = `${process.env.REACT_APP_PIPELINE_ENDPOINT}/api/v2/fetch-related-bq?page=${page}&pageSize=${pageSize}&categoryName=${categoryName}&pendingOnly=${pendingOnly}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result: QuestionsKnowledgeResponse = await response.json();

    return result;
  } catch (error) {
    console.error("Error in getQuestionsKnowledge:", error);
    throw error;
  }
}

export async function upsertRelatedKnowledge(payload: any): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_PIPELINE_ENDPOINT}/api/v2/update-related-bq`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    return result;
  } catch (error) {
    console.error("Error in upsertQuestionsKnowledge:", error);
    throw error;
  }
}

export async function uploadRelatedPinecone(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_PIPELINE_ENDPOINT}/api/v2/upload-related-pinecone`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    return result;
  } catch (error) {
    console.error("Error in uploadPinecone:", error);
    throw error;
  }
}

export async function getPriceKnowledge(
  page = 1,
  pageSize = 10,
  categoryName = "",
  pendingOnly = false
): Promise<PriceKnowledgeResponse> {
  try {
    const url = `${process.env.REACT_APP_PIPELINE_ENDPOINT}/api/v2/fetch-prices-bq?page=${page}&pageSize=${pageSize}&categoryName=${categoryName}&pendingOnly=${pendingOnly}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result: PriceKnowledgeResponse = await response.json();

    return result;
  } catch (error) {
    console.error("Error in getQuestionsKnowledge:", error);
    throw error;
  }
}

export async function upsertPriceKnowledge(payload: any): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_PIPELINE_ENDPOINT}/api/v2/update-prices-bq`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    return result;
  } catch (error) {
    console.error("Error in upsertQuestionsKnowledge:", error);
    throw error;
  }
}

export async function uploadPricePinecone(): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_PIPELINE_ENDPOINT}/api/v2/upload-prices-pinecone`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    return result;
  } catch (error) {
    console.error("Error in uploadPinecone:", error);
    throw error;
  }
}
