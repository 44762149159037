import { PostsList } from "../../../components/lists/PostsList";
import { ServiceRequestList } from "../../../components/service-requests";
import Title from "../../../components/shared/Title";

const ProviderView = ({ profileId }: { profileId: string }) => {
  return (
    <div className="flex flex-col gap-4">
      <div>
        <Title>Posts</Title>
        <br />
        <PostsList userId={profileId} />
      </div>
      <div>
        <Title>Service Requests</Title>
        <br />
        <ServiceRequestList userId={profileId} />
      </div>
    </div>
  );
};

export default ProviderView;
