import pako from 'pako';

// Function to decode and decompress the data
function decodeAndDecompress(base64String: string): string {
    const compressedData = atob(base64String); // Decode from base64
    const binaryData = new Uint8Array(compressedData.length);

    // Convert the base64 string to a binary array
    for (let i = 0; i < compressedData.length; i++) {
        binaryData[i] = compressedData.charCodeAt(i);
    }

    // Decompress the binary data using pako (gzip decompression)
    const decompressedData = pako.ungzip(binaryData, { to: 'string' });
    return decompressedData;
}

export default decodeAndDecompress;

