import { useEffect, useState } from "react";
import { BiArrowBack, BiMap, BiTimer } from "react-icons/bi";
import { Link, useLocation, useParams } from "react-router-dom";
import { ServiceRequestList } from "../../../components/service-requests";
import { FullPageLoader, Map, PageHeader } from "../../../components/shared";
import { routeNames } from "../../../routes";
import {
  CategoriesService,
  KnockService,
  ProfileService,
} from "../../../services";
import { Category, Knock, knockFlowMapping, Profile } from "../../../types";
import DateTimeDisplay from "../../../ui/dateTimeDisplay";
import TimeAgo from "timeago-react";
import { MINIMUM_KNOCK_COST_FOR_PREFFERED_PROVIDER } from "../../../constants";

export const KnockShowPage = () => {
  const knockId = useParams()["id"];

  const [knock, setKnock] = useState<Knock>();
  const [category, setCategory] = useState<Category>();
  const [customerProfile, setCustomerProfile] = useState<Profile>();

  const location = useLocation();

  useEffect(() => {
    const getKnock = async () => {
      if (knockId) {
        setKnock(await KnockService.getById(knockId));
      }
    };

    getKnock();
  }, [knockId]);

  useEffect(() => {
    const getCategoryAndProfile = async () => {
      if (knock) {
        setCategory(await CategoriesService.getById(knock.categoryId));
        setCustomerProfile(await ProfileService.getById(knock.userId));
      }
    };

    getCategoryAndProfile();
  }, [knock]);

  if (
    category === undefined ||
    knock === undefined ||
    customerProfile === undefined
  ) {
    return <FullPageLoader className="bg-slate-300" />;
  }
  const query = new URLSearchParams(location.search);

  return (
    <>
      <div className="flex items-end">
        <Link to={query.get("referrer") ?? routeNames["knocks.index.all"]}>
          <BiArrowBack size={30} className="mr-2" />
        </Link>
        <PageHeader>{`${category.name} request`}</PageHeader>
      </div>

      <div className="mt-6 grid md:grid-cols-2 xl:grid-cols-3 gap-4">
        <div className="bg-slate-100 p-4 rounded">
          <div className="flex flex-col justify-between">
            <div>
              <div className="text-xs font-bold uppercase mb-2">
                Customer details
              </div>
              <div>
                {customerProfile.name} {customerProfile.lastName}
              </div>
              <div className="text-sm">{customerProfile.email}</div>
              <div className="text-sm">{customerProfile.phoneNumber}</div>
              <div className="text-sm mt-4">
                <span>Accepting calls: </span>
                <b>{knock.isAcceptingCalls ? "Yes" : "No"}</b>
              </div>
              {knock.flow && (
                <div className="text-sm">
                  <span>User flow: </span>
                  <b>{knockFlowMapping[knock.flow]}</b>
                </div>
              )}
              {knock.channel && (
                <div className="text-sm">
                  <span>Channel: </span>
                  <b>{knock.channel}</b>
                </div>
              )}
              {knock.estimatedCost && (
                <div className="text-sm">
                  <span>AI Estimate: </span>
                  <b>${knock.estimatedCost}</b>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bg-slate-100 p-4 rounded">
          <div className="flex flex-col h-full justify-between">
            <div>
              <div className="text-xs font-bold uppercase mb-2">
                Service description
              </div>
              <div>{knock?.description}</div>
            </div>
            <div className="mt-2 text-sm flex items-center">
              <BiTimer className="mr-2" />
              <div>
                <DateTimeDisplay date={knock.createdAt} /> -{" "}
                <TimeAgo datetime={knock.createdAt} />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-slate-100 p-4 rounded">
          <div className="text-xs font-bold uppercase mb-2">
            Service Details
          </div>
          <div className="flex">
            <BiMap size={35} className="flex-0 mr-2" />
            <div>
              <div>{knock.addressMainText}</div>
              <div className="font-bold text-sm">
                {knock.addressSecondaryText}
              </div>
            </div>
          </div>
          {knock.estimatedCost &&
            knock.estimatedCost > MINIMUM_KNOCK_COST_FOR_PREFFERED_PROVIDER && (
              <p className="text-sm mt-4">
                <span className="badge badge-info">Preferred</span>
              </p>
            )}
        </div>
      </div>

      <div className="">
        <h2 className="font-bold mt-8 mb-4">Matching businesses</h2>
        <ServiceRequestList knockId={knock!.id} />
      </div>

      <div className="my-4 xl:w-2/3">
        <Map lat={knock.lat} lng={knock.lng} />
      </div>
    </>
  );
};
