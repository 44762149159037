import { Route } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { routeNames } from "./routeNames";
import { ProfilesIndexPage } from "../pages/profiles/ProfileIndexPage";
import ProfileShowPage from "../pages/profiles/ProfileShowPage";

export const ProfilesRoutes = (
  <>
    <Route
      path={routeNames["profiles.index"]}
      element={
        <ProtectedRoute>
          <ProfilesIndexPage></ProfilesIndexPage>
        </ProtectedRoute>
      }
    />
    <Route
      path={routeNames["profiles.show"](":profileId")}
      element={
        <ProtectedRoute>
          <ProfileShowPage></ProfileShowPage>
        </ProtectedRoute>
      }
    />
  </>
);
